<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Шүүлтүүр
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row class="d-flex ">
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Огноо</label>
          <!-- <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          /> -->
          <flat-pickr v-model="filtered_date" class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d' }"/>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Утасны дугаар</label>
          <b-form-input
                v-model="phone"
                class="d-inline-block mr-1"
                placeholder="9999999"
              />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <b-button
            variant="outline-primary"
            @click="filterEbarimt()"
            class="_report_submit_button"
          >
            <span class="text-nowrap">И-баримт хайх</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
    BFormInput
  },
  watch: {
  },
  props: {
    ebarimtFilter: {
      type: Object,
      default: null,
    }
  },
  data () {
    return {
      filtered_date: new Date(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0, 0)).toISOString().substring(0,10),
      phone: ''
    }
  },
  methods: {
    filterEbarimt() {
      if (!this.phone || this.phone === '' || this.phone === undefined) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Утасны дугаар хоосон байна',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.$emit('update:ebarimtFilter', { filtered_date: this.filtered_date, phone: this.phone})
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
  ._report_submit_button {
    margin-top: 19px;
  }
</style>
