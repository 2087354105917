import axios from '@axios'
import baseConfig from '@/config/index'
const EBARIMT_SERVICE_PATH = baseConfig.EBARIMT_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEbarimtData(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .get(EBARIMT_SERVICE_PATH + '/listEbarimtByDateAndPhone?filtered_date=' + payload.filtered_date + '&phone=' + payload.phone + '&page=' + payload.page + '&per_page=' + payload.per_page)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
